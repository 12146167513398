import { isAuthenticated } from "../../../../auth";

const { user } = isAuthenticated();

export const MaleStandardServices = [
	{
		serviceName: "haircut",
		serviceNameOtherLanguage: "قص الشعر",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "Super Haircut",
		serviceDescriptionOtherLanguage: "قص شعر سوبر",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
	{
		serviceName: "Children's haircut",
		serviceNameOtherLanguage: "قص شعر اطفال",
		customerType: "boys",
		customerTypeOtherLanguage: "الأولاد",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "Super Haircut",
		serviceDescriptionOtherLanguage: "قص شعر سوبر",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Hairdrying",
		serviceNameOtherLanguage: "سشوار",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
	{
		serviceName: "Beard shaving",
		serviceNameOtherLanguage: "حلاقة ذقن",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Beard coloring",
		serviceNameOtherLanguage: "صبغة الذقن",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Haircut + beard",
		serviceNameOtherLanguage: "قص شعر + ذقن",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 20,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Hair Coloring",
		serviceNameOtherLanguage: "صبغ الشعر",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Protein hair straightener",
		serviceNameOtherLanguage: "فرد شعر بالبروتين",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Hair Straightening",
		serviceNameOtherLanguage: "فرد الشعر",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Threading",
		serviceNameOtherLanguage: "فتلة",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 20,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Groom Offer",
		serviceNameOtherLanguage: "عرض العريس",
		customerType: "male",
		customerTypeOtherLanguage: "ذكر",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 20,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
];

export const FemaleStandardServices = [
	{
		serviceName: "Face + eyebrows",
		serviceNameOtherLanguage: "وش وحواجب",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
	{
		serviceName: "Facial Threading",
		serviceNameOtherLanguage: "وش فتلة ",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Facial waxing ",
		serviceNameOtherLanguage: "وش شمع ",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
	{
		serviceName: "Eyebrows",
		serviceNameOtherLanguage: "حواجب",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Mustache",
		serviceNameOtherLanguage: "شنب",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Haircut",
		serviceNameOtherLanguage: "قص شعر",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Hairdrying",
		serviceNameOtherLanguage: "سشوار",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Babyliss",
		serviceNameOtherLanguage: "مكوه",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Hairdrying + babyliss",
		serviceNameOtherLanguage: "سشوار + مكوه",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},

	{
		serviceName: "Bride Offer",
		serviceNameOtherLanguage: "عرض العروسة",
		customerType: "female",
		customerTypeOtherLanguage: "أنثى",
		servicePrice: "",
		servicePriceDiscount: "",
		serviceTime: "",
		serviceLoyaltyPoints: 10,
		serviceType: "package service",
		serviceDescription: "",
		serviceDescriptionOtherLanguage: "",
		belongsTo: user ? user._id : "",
		catchyPhrase: "",
		catchyPhraseOtherLanguage: "",
		bundleService: false,
		bundleServicesAdded: [],
		activeService: true,
	},
];
