/** @format */

import React, { useEffect, useContext, useReducer } from "react";
import reducer from "./cart_reducer";
import {
	ADD_TO_CART,
	REMOVE_CART_ITEM,
	TOGGLE_CART_ITEM_AMOUNT,
	CLEAR_CART,
	COUNT_CART_TOTALS,
	SIDEBAR_OPEN,
	SIDEBAR_CLOSE,
	SHIPPING_FEES,
	SHIPPING_DETAILS,
} from "../../../actions";

const getLocalStorage = () => {
	let cart = localStorage.getItem("cart");
	if (cart) {
		return JSON.parse(localStorage.getItem("cart"));
	} else {
		return [];
	}
};

const initialState = {
	isSidebarOpen: false,
	cart: getLocalStorage(),
	total_items: 0,
	total_amount: 0,
	shipping_fee: 0,
	shipmentChosen: {},
};

const CartContext = React.createContext();

export const CartProvider2 = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const openSidebar = () => {
		dispatch({ type: SIDEBAR_OPEN });
	};
	const closeSidebar = () => {
		dispatch({ type: SIDEBAR_CLOSE });
	};

	// add to cart
	const addToCart = (id, color, amount, product) => {
		dispatch({ type: ADD_TO_CART, payload: { id, color, amount, product } });
	};
	// remove item
	const removeItem = (id) => {
		dispatch({ type: REMOVE_CART_ITEM, payload: id });
	};
	// toggle amount
	const toggleAmount = (id, value) => {
		// console.log(id, value);
		dispatch({ type: TOGGLE_CART_ITEM_AMOUNT, payload: { id, value } });
	};
	// clear cart
	const clearCart = () => {
		dispatch({ type: CLEAR_CART });
	};

	const addShipmentFee = (ShippingPrice) => {
		dispatch({ type: SHIPPING_FEES, payload: { ShippingPrice } });
	};

	const addShipmentDetails = (chosenShipmentDetails) => {
		dispatch({ type: SHIPPING_DETAILS, payload: { chosenShipmentDetails } });
	};

	useEffect(() => {
		dispatch({ type: COUNT_CART_TOTALS });
		localStorage.setItem("cart", JSON.stringify(state.cart));
	}, [state.cart]);

	return (
		<CartContext.Provider
			value={{
				...state,
				addToCart,
				removeItem,
				toggleAmount,
				clearCart,
				openSidebar,
				closeSidebar,
				addShipmentFee,
				addShipmentDetails,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};
// make sure use
export const useCartContext2 = () => {
	return useContext(CartContext);
};
